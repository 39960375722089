<template>
  <div class="page">
   <!-- <yd-tabbar>
        <yd-tabbar-item title="首页" active link="/tuobenShopList">
            <yd-icon name="home-outline" slot="icon" size="0.54rem"></yd-icon>
        </yd-tabbar-item>
        <yd-tabbar-item title="订单列表" link="#" dot>
            <yd-icon name="shopcart-outline" slot="icon" size="0.54rem"></yd-icon>
        </yd-tabbar-item>
    </yd-tabbar> -->
    <tuobenFooter></tuobenFooter>
  </div>
</template>

<script>
import Test1 from "@/assets/img_home_fangjian01@3x.png";
import Test2 from "@/assets/img_home_tuangou02@3x.png";
import ItemList from "@/components/list/ItemList";
import RowItemList from "@/components/list/RowItemList";
import Empty from "@/components/empty/Empty";
import Search from "@/components/index/Search";
import { getItemList } from "@/services";
import { wxShare } from "@/utils/common";
import HeadNav from "@/components/common/HeadNav";
import tuobenFooter from "@/components/footer/tuobenFooter.vue";
export default {
  name: "SpecialTopic",
  components: {
    tuobenFooter
  },
  inject: ["reload"],
  data() {
    return {
      list: [
        {
          img: "//img1.shikee.com/try/2016/06/23/14381920926024616259.jpg",
          title:
            "标题111标题标题标题标题哒哒哒哒哒哒多多多多多多多多多多多多多多多多多多多多多",
          price: 156.23,
          w_price: 89.36,
          de:
            "少时诵诗书所所所所扩扩扩扩扩扩扩扩扩扩卡奥奥奥奥奥绿绿绿绿绿绿绿绿女女女女女女女"
        },
        {
          img: "//img1.shikee.com/try/2016/06/21/10172020923917672923.jpg",
          title: "标题222标题标题标题标题",
          price: 256.23,
          w_price: 89.36,
          de:
            "少奥奥奥绿绿绿绿绿绿绿绿女女女女女女女"
        },
        {
          img: "//img1.shikee.com/try/2016/06/23/15395220917905380014.jpg",
          title: "标题333标题标题标题标题",
          price: 356.23,
          w_price: 89.36
        },
        {
          img: "//img1.shikee.com/try/2016/06/25/14244120933639105658.jpg",
          title: "标题444标题标题标题标题",
          price: 456.23,
          w_price: 89.36
        },
        {
          img: "//img1.shikee.com/try/2016/06/26/12365720933909085511.jpg",
          title: "标题555标题标题标题标题",
          price: 556.23,
          w_price: 89.36
        },
        {
          img: "//img1.shikee.com/try/2016/06/19/09430120929215230041.jpg",
          title: "标题666标题标题标题标题",
          price: 656.23,
          w_price: 89.36
        }
      ],
       heigh: '0'
    };
  },
  mounted() {
    // console.log(this.$refs.a.clientHeight)
  },
  methods: {
    buy(item) {
      console.log(item);
    }
  },
  activated() {
    this.name = this.$route.query.name ? this.$route.query.name : "";
    if (this.name) document.title = this.name;
    // this.loadItemList()
  }
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.page {
    width:100%;
    height:100%;
 /deep/ .yd-tabbar{
     position: absolute;
     bottom: 0;
 }
}
</style>
